<script>
export default {
    props:{
        type:String,
        status:String,
    },
    computed:{
        data(){
            if(this.type=="blog"){
                return {
                    "P": {style:"success",label:"Publish"},
                    "DR": {style:"warning",label:"Draft"},
                }[this.status.toUpperCase()] || {}
            }
            else if(this.type=="testimoni"){
                return {
                    "Y": {style:"success",label:"Featured"},
                    "N": {style:"warning",label:"No Featured"},
                }[this.status.toUpperCase()] || {}
            }
            return {
                "Y": {style:"success",label:"Active"},
                "N": {style:"warning",label:"Inactive"},
                "D": {style:"info",label:"Default"},
            }[this.status.toUpperCase()]
        }
    }
}
</script>

<template>
    <label :class="'label label-'+(data.style)">{{data.label}}</label>
</template>